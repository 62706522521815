import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import VeeValidate, { Validator } from 'vee-validate';
//import ru from 'vee-validate/dist/locale/ru';

Vue.config.productionTip = false;


/*Validator.localize('ru', ru);
Vue.use(VeeValidate, {
  locale: 'ru',
});*/

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
