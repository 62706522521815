<template>
  <v-app>
    <v-main>
      <v-container v-if="status == 'info'" fluid class="ma-6">
        <v-row>
          <v-col class="mb-4 text-info">
            <p class="text-h5 text-center">
              Добро пожаловать в приложение Сервис по подбору персонала от «Эм Си Арт»!
            </p>
			<hr class="hr-text">
			<p class="font-weight-bold text-subtitle-1">
				Принцип работы и настройка приложения.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				Подбор HH — это система на базе Битрикс24 для автоматизации работы 
				рекрутеров. Приложение позволяет упростить подбор персонала в компании.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				После установки в CRM появляется новая воронка ATC. Сервис подбора 
				работает только в данной воронке, где вакансии представлены 
				как «Сделки», а Кандидаты — «Контакты».
			</p>
			<p class="font-weight-regular text-subtitle-1">
				Пополнение базы контактов возможно через заведение вручную либо 
				<span class="font-weight-bold">через через расширение Chrome<span>.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				<span class="font-weight-bold">Для это необходимо скачать расширение</span> 
				в магазине Google Chrome по названию 
				<a href="https://chrome.google.com/webstore/detail/kokncoflcibchmmjkhdjdfdhmaiheahn/" target="_blank">
					«Импорт резюме в Битрикс24»
				</a>.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				После установки расширения необходимо создать вебхук (ключ) для связи с 
				порталом. Для этого необходимо перейти в раздел «Разработчикам» - «Другое» - «Входящий вебхук».
				Перед Вами откроется окно, где будет указан вебхук. Обязательно 
				необходимо указать права к CRM и Спискам при сохранении, иначе 
				контакты не будут импортироваться.
			</p>
			<img src="./images/img1.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				Скопированный вебхук необходимо внести и сохранить в 
				настройках расширения «Импорт из HH» Chrome. Установка 
				выбора в поле «Обновлять контакт, если он уже создан 
				для текущего резюме» позволяет обновлять данные в 
				контакте, если ранее кандидат уже был добавлен в CRM. 
			</p>
			<img src="./images/img2.png" class="text-images-small">
			<p class="font-weight-bold text-subtitle-1">
				Кнопка «Добавить резюме» будет активна только на сайте Head Hunter, 
				т.к. расширение не предназначено для импорта информации с других сайтов.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				Выбираем нужное нам резюме на сайте, открываем его и в расширении нажимаем 
				«Добавить резюме в базу». 
			</p>
			<img src="./images/img3.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				После успешного импорта Вы увидите диалоговое окно о том, что карточка кандидата 
				находится на распознавании. 
			</p>
			<img src="./images/img4.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				Также Вам придет уведомление на портале о том, что контакт 
				успешно создан. 
			</p>
			<img src="./images/img5.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				Контакт будет содержать всю информацию из резюме: ФИО, контактные данные, 
				опыт, образование, фото, ключевые навыки о кандидате. 
			</p>
			<p class="font-weight-regular text-subtitle-1">
				Работать с контактом необходимо в специальном разделе 
				«Вакансия/статус». Он будет доступен в контакте в меню «Еще», 
				также его можно добавить в основной список, как это реализовано 
				на картинке. 
			</p>
			<img src="./images/img6.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				В разделе «Вакансия / статус» будут отображаться все созданные 
				сделки в воронке ATC. Здесь мы управляем кандидатом и его этапами 
				прохождения интервью. Одного кандидата возможно добавить в 
				несколько вакансий (если это необходимо). 
			</p>
			<img src="./images/img7.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				Кандидат автоматически попадает в указанную сделку (вакансию), 
				в которой мы можем посмотреть список всех кандидатов.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				Сделка (Вакансия) создаётся вручную. В ней возможно указать все 
				необходимы параметры через дополнительные пользовательские поля 
				(заработная плата, опыт, образование и т.д.). 
			</p>
			<img src="./images/img8.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				Все контакты, которые мы рассматриваем на эту должность, будут 
				отображаться в «Candidates in progress». Здесь Вы увидите ФИО и 
				стадию. Кликнув на имя контакта, можно быстро перейти в него и 
				изменить стадию, как было показано выше. 
			</p>
			<img src="./images/img9.png" class="text-images">
			<p class="font-weight-regular text-subtitle-1">
				Можно менять стадию вакансий. Финальная стадия — «Предложение 
				о работе» или «Сделка проиграна». Если работа с вакансией 
				не завершилась предложением о работе, то рекрутер продолжает поиск.
			</p>
			<p class="font-weight-regular text-subtitle-1">
				Сервис по подбору персонала от компании «Эм Си Арт» позволяет 
				интегрировать работу по рекрутингу в систему CRM Битрикс 
				без дополнительных переносов контактов. Любое резюме, 
				которое открыто для Вас, за минуту превратиться в контакт CRM! 
			</p>
			<p class="font-weight-bold text-subtitle-1">
				Без труда реализуем единую автоматизированную систему для HR-специалистов в Вашей компании! 
				Для ознакомления заполните заявку на сайте: 
					<a href="https://www.mcart.ru/start-project/" target="_blank">
						https://www.mcart.ru/start-project/
					</a>, и наши специалисты с 
				удовольствием расскажут и продемонстрируют возможные решения. 
			</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-else>
        <contact-form
          @closeForm="closeForm($event)"
        >
        </contact-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const axios = require('axios')

export default {
  name: 'App',
  components: {
		ContactForm: () => import("./components/ContactForm"),
  },
  data: () => ({
    status: 'info',
  }),

  methods: {
    getUserContactInfo() {
			let formStatus = localStorage.getItem('ats_form_send');
			
			if (formStatus == 'send') {
				this.status = 'info';
			} else if (formStatus == 'not_send') {
				this.status = 'contact_form';
			} else {
				let bitrix_user = false;
				const BX24 = window['BX24'];
				var self = this;
        let authData = BX24.getAuth();

				BX24.callMethod('user.current', {'AUTH_ID': authData.access_token}, function(res) {
					if ('ID' in res.data()) {
						bitrix_user = res.data().ID;
            
						const domain = BX24.getDomain();

						const params = {
							domain: domain,
							current_user: bitrix_user,
						}

						self.makeFormRequest(params)
					}					
				});				
			}
		},
		async makeFormRequest(params) {
      let res = await axios.get(`/contactform?domain=${params.domain}&current_user=${params.current_user}`);
			
			if ('status' in res.data) {
				localStorage.setItem('ats_form_send', res.data.status);

				if (res.data.status == 'send') {
					this.status = 'info';
				} else {
					this.status = 'contact_form';
				}
			}
		},
    closeForm(is_send) {
			this.status = 'info';

			if (is_send) {
				localStorage.setItem('ats_form_send', 'send');
			} else {
				localStorage.setItem('ats_form_send', 'not_send');
			}
		},
  },
  created(){
    if (window['BX24']) {
			this.getUserContactInfo();
		} else {
			window.addEventListener('load', this.getUserContactInfo)
		}
  },
  mounted() {
    let b24Script = document.createElement('script');
    b24Script.setAttribute('src', 'https://api.bitrix24.com/api/v1/');
    document.head.appendChild(b24Script);
  },
};
</script>

<style>
html, body, #app {
  background-color: #edf2f4;
  min-height: 100%;
}

.text-info {
	margin: 0 120px 0 80px;
    border-radius: 10px;
    background-color: white;
	padding: 30px;
}

.v-main__wrap {
	background-color: #4889CA;
}

hr.hr-text {
	margin-bottom: 20px;
}

.text-images {
	width: 70%;
    margin-left: 15%;
}

.text-images-small {
	width: 50%;
    margin-left: 25%;
}
</style>